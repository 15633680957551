<template>
  <div>
    <b-card class="dense">
      <div class="text-center h4 p-0 m-0 text-dark">
        Order Summary
      </div>
    </b-card>
    <b-row>
      <b-col sm="8" md="6" lg="6" class="offset-sm-2 offset-md-0 offset-lg-0">
        <!-- Cart Items -->
        <div v-if="cart.length" class="" style="">
          <div
            v-for="(item, index) in cart"
            :key="item.name + index"
            class="d-flex py-1"
            style="border-bottom: 1px solid #cccccc55;max-height: 100px"
          >
            <b-img
              :src="
                'https://omnimenuio.imgix.net/' +
                  item.url +
                  '?ixlib=vue-2.8.4&auto=format&fit=crop&crop=entropy&h=300&w=300&dpr=1&q=75'
              "
              :alt="item.name"
              rounded
              width="72px"
            />
            <div class="d-flex justify-content-between flex-grow-1">
              <div class="bg-white text-dark font-weight-bolder" style="padding-left: 3%;">
                {{ item.name }}
              </div>
              <div class="d-flex justify-content-around">
                <div
                  class="d-flex flex-column align-items-center justify-content-around h-100"
                >
                  <h5 class="mx-1">${{ item.totalPrice }}</h5>
                </div>
                <b-form-spinbutton
                  :value="item.quantity"
                  min="0"
                  size="sm"
                  vertical
                  @change="updateItemQuantity(item.cartItemId, $event)"
                />
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col sm="8" md="6" lg="6" class="offset-sm-2 offset-md-0 offset-lg-0">
        <div class="d-flex flex-column justify-content-around align-items-center my-1">
          <div class="d-flex flex-column w-100" style="padding: 2px">
            <div class="my-1 font-weight-bolder font-medium-2">
              Price Details
            </div>
            <div class="price-details">
              <div class="detail-title">
                Subtotal
              </div>
              <div>${{ subTotal }}</div>
            </div>
            <div class="price-details mb-0">
              <div class="detail-title">
                Tax and fees
                <feather-icon id="popover-tax" icon="InfoIcon" size="15" />
              </div>
              <b-popover target="popover-tax" triggers="hover" placement="top">
                <template #title>
                  Taxes and Fees
                </template>
                <div class="font-weight-bold">
                  Sales Tax - ${{ tax }} <br />
                  Service Fees: ${{ serviceFees }}
                </div>
              </b-popover>
              <div>${{ (tax * 100 + serviceFees * 100) / 100 }}</div>
            </div>
            <div hidden class="price-details">
              <div class="detail-title">
                Tip
              </div>
              <div>$8</div>
            </div>
            <hr class="w-100" />
            <div class="mb-1 font-weight-bolder font-medium-2 d-flex justify-content-between">
              <div class="detail-title">
                Total
              </div>
              <div>${{ total }}</div>
            </div>
          </div>
          <b-button
            block
            variant="dark"
            size="lg"
            style="border-radius: 0"
            :disabled="cartCount === 0"
            @click="checkout"
          >
            Review Order
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<style>
.price-details {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  margin-bottom: 8px;
}
</style>
<script>
import { BCard, BRow, BCol, BImg, BFormSpinbutton, BButton, BPopover } from 'bootstrap-vue'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'Checkout',
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BFormSpinbutton,
    BButton,
    BPopover,
  },
  data() {
    return {
      clientSecret: '',
      card: null,
      isLoading: true,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      showLogin: state => state.user.showLogin,
      cart: state => state.cart.added,
      cartCount: state =>
        state.cart.added.reduce(
          (item, current) => ({ quantity: item.quantity + current.quantity }),
          { quantity: 0 }
        ).quantity,
    }),
    serviceFees() {
      return this.cartCount > 0 ? 1 : 0
    },
    subTotal() {
      let total = 0
      this.cart.forEach(i => {
        total += i.totalPrice
      })
      return total.toFixed(2)
    },
    tax() {
      return (this.subTotal * 0.086).toFixed(2)
    },
    total() {
      return (this.subTotal * 1.086 + this.serviceFees).toFixed(2)
    },
  },
  methods: {
    ...mapMutations({
      toggleLogin: 'user/toggleLogin',
    }),
    checkout() {
      if (!this.user.mobile || new Date(this.user.expires) < new Date()) {
        // console.log(this.user)
        this.toggleLogin()
      } else this.$router.push('review-order')
    },
    updateItemQuantity(cartItemId, quantity) {
      if (quantity === 0) {
        this.$store.dispatch('cart/removeItemFromCart', cartItemId)
      } else this.$store.dispatch('cart/updateCartItemQuantity', { cartItemId, quantity })
    },
  },
}
</script>

<style scoped></style>
